import { useState, useEffect, useContext, useCallback } from 'react';
import api from '../api';
import { AuthContext } from '../contexts/AuthContext';

const useChatLogic = ({ anonymous, numAgents }) => {
  const [userPrompt, setUserPrompt] = useState('');
  const [questionId, setQuestionId] = useState(null);
  const [randomQuestion, setRandomQuestion] = useState('');
  const [randomQuestionId, setRandomQuestionId] = useState(null);
  const [responses, setResponses] = useState(Array(numAgents).fill(undefined));
  const [responseIds, setResponseIds] = useState([]);
  const [agentNames, setAgentNames] = useState([]);
  const [feedback, setFeedback] = useState({});
  const [comparisonResult, setComparisonResult] = useState('');
  const [comparisonId, setComparisonId] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { showLoginModal } = useContext(AuthContext);
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  const fetchAgents = useCallback(async () => {
    try {
      const response = await api.get('/agents/?format=json');
      setAgents(response.data.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (err) {
      console.error('Failed to fetch agents:', err);
      setError('Failed to fetch agents.');
    }
  }, []);

  useEffect(() => {
    if (!anonymous) {
      fetchAgents();
    }
  }, [anonymous, fetchAgents]);

  const resetForm = () => {
    setResponses(Array(numAgents).fill(undefined));
    setResponseIds([]);
    setAgentNames([]);
    setFeedback({});
    setComparisonResult('');
    setComparisonId(null);
    setError('');
  };

  const handleSend = async () => {
    // Check if we're on a share page
    const currentPath = window.location.pathname;
    if (currentPath.startsWith('/share/')) {
      // Determine the correct path based on anonymity
      const newPath = anonymous ? '/battle-royale' : '/duel';
      // Update the URL without reloading the page
      window.history.pushState({}, '', newPath);
    }

    if (!userPrompt.trim()) return;
    if (!anonymous) {
      if (selectedAgents.length !== numAgents) {
        setError(`Please select ${numAgents} agent${numAgents > 1 ? 's' : ''}.`);
        return;
      }
      const uniqueAgents = new Set(selectedAgents);
      if (uniqueAgents.size !== selectedAgents.length) {
        setError('Please select different agents.');
        return;
      }
    }
    try {
      resetForm();
      setIsLoading(true);

      let qid;
      if (userPrompt === randomQuestion) {
        qid = randomQuestionId;
      } else {
        const questionResponse = await api.post('/questions/create/', {
          text: userPrompt,
        });
        const question = questionResponse.data;
        qid = question.id;
      }
      setQuestionId(qid);

      const responsesResponse = await api.post(`/responses/generate/`, {
        question: qid,
        agents: anonymous ? [0, 0] : selectedAgents,
      });
      const generatedResponses = responsesResponse.data;

      if (generatedResponses.length >= numAgents) {
        setResponseIds(generatedResponses.map(resp => resp.id));
        setResponses(generatedResponses.map(resp => resp.error || resp.text));
        if (!anonymous) {
          const names = selectedAgents.map(agentId => {
            const agent = agents.find(a => a.id === parseInt(agentId));
            return agent ? agent.name : '';
          });
          setAgentNames(names);
        }
      } else {
        setError('Failed to get enough responses.');
      }
      setError('');
    } catch (err) {
      if (err.response && err.response.status === 401) {
        showLoginModal();
      } else {
        setError('Failed to generate responses.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUseRandomQuestion = async () => {
    try {
      resetForm();
      setIsLoading(true);

      const response = await api.get('/questions/random/');
      const question = response.data;
      setUserPrompt(question.text);
      setRandomQuestion(question.text);
      setRandomQuestionId(question.id);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        showLoginModal();
      } else {
        setError('Failed to fetch random question.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleComparisonSubmit = async (result) => {
    setComparisonResult(result);
    try {
      // 5. Submit Comparison
      const payload = {
        question: questionId,
        response1: responseIds[0],
        response2: responseIds[1],
        result,
      };
      const response = await api.post('/feedback/comparison/submit/', payload);

      // Extract agent names from the response if available
      const comparisonData = response.data;
      if (comparisonData.agent1 && comparisonData.agent2) {
        setAgentNames([comparisonData.agent1.name, comparisonData.agent2.name]);
        setComparisonId(comparisonData.id);
      }

      setError('');
    } catch (err) {
      setError('Failed to submit comparison.');
    }
  };

  const handleFeedbackSubmit = async (index) => {
    try {
      const fb = feedback[index];
      if (fb && fb.trim()) {
        await api.post('/feedback/submit/', {
          response: responseIds[index],
          text: fb,
        });
        
        const newFeedback = { ...feedback };
        delete newFeedback[index];
        setFeedback(newFeedback);
        
        setError('');
        setSuccessMessage('Feedback submitted successfully.');
        setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
      }
    } catch (err) {
      setError('Failed to submit feedback.');
    }
  };

  // Function to initialize from UUID
  const initializeFromUUID = useCallback(async (uuid) => {
    try {
      setIsLoading(true);
      const response = await api.get(`/shares/comparison/${uuid}/`);
      const { question, response1, response2 } = response.data.comparison;

      setUserPrompt(question);
      setSelectedAgents([response1.agent, response2.agent]);
      setResponses([response1.error || response1.text, response2.error || response2.text]);

      setError('');
    } catch (err) {
      console.error('Failed to initialize from UUID:', err);
      setError('Failed to load shared comparison.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setAgentNames([
      agents.find(agent => agent.id === selectedAgents[0])?.name || `Agent A`,
      agents.find(agent => agent.id === selectedAgents[1])?.name || `Agent B`
    ]);
  }, [agents, selectedAgents]);

  return {
    userPrompt,
    setUserPrompt,
    agents,
    selectedAgents,
    setSelectedAgents,
    responses,
    responseIds,
    agentNames,
    feedback,
    setFeedback,
    comparisonResult,
    comparisonId,
    error,
    isLoading,
    handleSend,
    handleUseRandomQuestion,
    handleComparisonSubmit,
    handleFeedbackSubmit,
    successMessage,
    setSuccessMessage,
    initializeFromUUID,
  };
};

export default useChatLogic;
